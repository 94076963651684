<template>
  <layout>
    <template #title>
      复议要素提取
    </template>
    <div class="extraction-area">
      <el-card class="extraction-panel">
        <div class="left-panel">
          <div class="card-title">
            复议件主题词
          </div>
          <div class="extraction-origin">
            <el-input type="textarea" placeholder="在这里输入" v-model="generateParam.origin">
            </el-input>
          </div>
          <div class="extraction-start">
            <button class="extraction-start-btn"
                    @click="start"
                    :disabled="!generateParam.origin || extractLoading || summaryLoading">
              生成要素
            </button>
          </div>
        </div>
      </el-card>
      <el-card class="extraction-panel">
        <div class="extraction-function" v-loading="extractLoading">
          <div class="card-title">
            要素提取
          </div>
          <el-form label-width="auto" size="large">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="姓名">
                  <el-input v-model="generateResult.name" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码">
                  <el-input v-model="generateResult.phone" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="身份证号">
                  <el-input v-model="generateResult.idnumber" />
                </el-form-item>
              </el-col>
              <el-col :span="12"></el-col>
            </el-row>
            <el-row :gutter="20" class="address-row">
              <el-col :span="24">
                <el-form-item label="家庭住址">
                  <el-input type="textarea" v-model="generateResult.address">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="extraction-function" v-loading="summaryLoading">
          <div class="card-title">
            摘要生成
            <el-checkbox-group v-model="generateParamLen" class="extraction-len">
              <el-checkbox label="0">短</el-checkbox>
              <el-checkbox label="1">中</el-checkbox>
              <el-checkbox label="2">长</el-checkbox>
            </el-checkbox-group>
          </div>
          <el-input type="textarea" v-model="generateResult.summary">
          </el-input>
        </div>
      </el-card>
    </div>
  </layout>
</template>

<script>
import layout from '@/views/fuyiTool/common/layout.vue'
import axios from 'axios'
import qs from 'qs'
import { ElMessage } from 'element-plus/lib/components'

export default {
  components: {
    layout
  },
  computed: {
    generateParamLen: {
      get() {
        return [this.generateParam.len]
      },
      set(val) {
        this.generateParam.len = val[1]
      }
    }
  },
  watch: {
    'generateParam.len'(nv) {
      this.summary()
    }
  },
  data() {
    return {
      extractLoading: false,
      summaryLoading: false,
      generateParam: {
        len: '1',
        origin: undefined
      },
      generateResult: {
        name: undefined,
        phone: undefined,
        idnumber: undefined,
        address: undefined,
        summary: undefined
      }
    }
  },
  methods: {
    start() {
      if (!this.generateParam.origin) {
        return
      }
      this.summary()
      this.extract()
    },
    summary() {
      if (!this.generateParam.origin) {
        return
      }
      const param = qs.stringify({
        query: this.generateParam.origin,
        zhaiyao_length: this.generateParam.len
      })
      this.summaryLoading = true
      axios.post('/fuyi-tool-api/chatglm-6b-xf/zhaiyao', param)
        .then(response => {

          this.generateResult.summary = response.data.response
          this.summaryLoading = false
        })
        .catch(error => {
          ElMessage({
            message: '请求失败',
            type: 'warning'
          })
          console.error(error)
          this.summaryLoading = false
        })
    },
    extract() {
      if (!this.generateParam.origin) {
        return
      }
      const param = qs.stringify({
        query: this.generateParam.origin,
        zhaiyao_length: this.generateParam.len
      })
      this.extractLoading = true
      axios.post('/fuyi-tool-api/chatglm-6b-xf/chouqv', param)
        .then(response => {
          let personMap = {};
          (response.data.response || []).forEach(item => {
            const personName = item[0]
            if (!personMap[personName]) {
              personMap[personName] = {}
            }
            const fieldName = item[1]
            const fieldValue = item[2]
            if (fieldName === '信访人地址') {
              personMap[personName].address = fieldValue
            }
            if (fieldName === '手机号码') {
              personMap[personName].phone = fieldValue
            }
            if (fieldName === '身份证号') {
              personMap[personName].idnumber = fieldValue
            }
          })
          let persons = []
          Object.keys(personMap).forEach(k => {
            persons.push({
              name: k,
              phone: personMap[k].phone,
              idnumber: personMap[k].idnumber,
              address: personMap[k].address
            })
          })
          if (persons.length > 0) {
            let person = persons[0]
            this.generateResult.name = person.name
            this.generateResult.phone = person.phone
            this.generateResult.idnumber = person.idnumber
            this.generateResult.address = person.address
          }
          this.extractLoading = false
        })
        .catch(error => {
          ElMessage({
            message: '请求失败',
            type: 'warning'
          })
          console.error(error)
          this.extractLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.extraction-area {
  height: 100%;
  display: flex;
}

.extraction-panel {
  flex: 1;
  margin: 20px;
  margin-left: 0;
  border-radius: 10px;

  &:first-child {
    margin-left: 20px;
  }
}

.card-title {
  position: relative;
  border-left: 8px solid #ffb400;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 16px;
  color: #31409a;
  // margin-left: -20px;
  margin-bottom: 15px;
}

.extraction-len {
  position: absolute;
  top: -2px;
  left: 75px;
  margin-left: 30px;
}

:deep(.el-card__body) {
  height: 100%;
}


.left-panel {
  height: 100%;
  display: flex;
  flex-direction: column;

  .extraction-origin {
    flex: 1;

    :deep(.el-textarea) {
      height: 100%;
    }

    :deep(.el-textarea__inner) {
      border-radius: 10px;
      resize: none;
      height: 100%;
    }
  }

  .extraction-start {
    text-align: center;
    margin-top: 15px;

    .extraction-start-btn {
      border-width: 0;
      color: #fff;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
      width: 150px;
      height: 40px;
      border-radius: 5px;
      cursor: pointer;
      background: linear-gradient(to right, #5D86FB, #3D69E7);
      transition: all 0.2s ease;

      //&[disabled] {
      //  background: linear-gradient(to right, #b8c8f8, #95abee);
      //  filter: grayscale(100%);
      //}

      &:hover {
        opacity: 0.85;
      }

      &:not([disabled]):active {
        opacity: 1;
      }
    }
  }

}

.extraction-function {
  height: 50%;
  display: flex;
  flex-direction: column;

  :deep(.el-textarea) {
    height: 100%;
  }

  :deep(.el-textarea__inner) {
    border-radius: 10px;
    resize: none;
    height: 100%;
  }

  .el-form {
    flex: 1;
    display: flex;
    flex-direction: column;

    .address-row {
      flex: 1;

      .el-form-item {
        height: calc(100% - 20px);
      }
    }
  }

}
</style>